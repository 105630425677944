<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/template`"
                title="Template"
                :columns="columns"
                :formOptions="formOptions"
                :template="true"
                :newPage="false"
                popUpCompnent="addTemplate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'Type',
          field: 'subject',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Type'
          }
        },
        {
          label: 'Template Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Template Name'
          }
        },


        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Template' : 'Add Template'}`,
        submitRouterPath: '/setup/template',
        gridForm: true,
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/gettemplateById`,
        url: `${baseApi}/template`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Company Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6'
            // required: true,
          },
        ]
      }
    }
  },
  beforeMount () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
